import React from "react"

import Header from "../ProductsPage/Header"
import Video from "../video"

import laserVideo from "../../assets/video/Laser-Spy-Video.mp4"

const ProductHome = ({ productRef, product }) => {
    console.log(productRef)
    let backvideoPath = "/images/" + productRef.productName.replace(" ", "-").toLowerCase() + "/" + productRef.productName.replace(" ", "-").toLowerCase() +  "-video.mp4"
    return(
        <div className="productHome" id="productHome">
            <Header />
            <video className="background-video" playsinline="" autoplay="" preload="auto" muted loop>
                <source src={backvideoPath} type="video/mp4"></source>
            </video>
            <div className="productHomeTitle">
                <h1>{ productRef.productName.split(" ")[0] } <span>{ productRef.productName.split(" ")[1] }</span></h1>
                <h3>{ product.subtitle }</h3>
                <div className="links pro-page"><a href="#video">Watch Video</a></div>
                <div className="links pro-page"><a href="#features">Dive In</a></div>
            </div>
            <div className="graphVector">
                <svg xmlns="http://www.w3.org/2000/svg" width="102.5" height="585" viewBox="0 0 111 633.5">
                    <g id="Group_1" data-name="Group 1" transform="translate(-150 -40)">
                        <g id="Polygon_1" data-name="Polygon 1" transform="translate(181 40) rotate(90)" fill="none">
                            <path d="M27,0l9,15.5L27,31H9L0,15.5,9,0Z" stroke="none" />
                            <path d="M 10.72712326049805 3 L 3.469058990478516 15.5 L 10.72712326049805 28 L 25.27287673950195 28 L 32.53094100952148 15.5 L 25.27287673950195 3 L 10.72712326049805 3 M 9 0 L 27 0 L 36 15.5 L 27 31 L 9 31 L 0 15.5 L 9 0 Z" stroke="none" fill="#fff" />
                        </g>
                        <g id="Polygon_2" data-name="Polygon 2" transform="translate(261 159.5) rotate(90)" fill="none">
                            <path d="M27,0l9,15.5L27,31H9L0,15.5,9,0Z" stroke="none" />
                            <path d="M 10.72712326049805 3 L 3.469058990478516 15.5 L 10.72712326049805 28 L 25.27287673950195 28 L 32.53094100952148 15.5 L 25.27287673950195 3 L 10.72712326049805 3 M 9 0 L 27 0 L 36 15.5 L 27 31 L 9 31 L 0 15.5 L 9 0 Z" stroke="none" fill="#fff" />
                        </g>
                        <g id="Polygon_3" data-name="Polygon 3" transform="translate(181 279) rotate(90)" fill="none">
                            <path d="M27,0l9,15.5L27,31H9L0,15.5,9,0Z" stroke="none" />
                            <path d="M 10.72712326049805 3 L 3.469058990478516 15.5 L 10.72712326049805 28 L 25.27287673950195 28 L 32.53094100952148 15.5 L 25.27287673950195 3 L 10.72712326049805 3 M 9 0 L 27 0 L 36 15.5 L 27 31 L 9 31 L 0 15.5 L 9 0 Z" stroke="none" fill="#fff" />
                        </g>
                        <g id="Polygon_4" data-name="Polygon 4" transform="translate(261 398.5) rotate(90)" fill="none">
                            <path d="M27,0l9,15.5L27,31H9L0,15.5,9,0Z" stroke="none" />
                            <path d="M 10.72712326049805 3 L 3.469058990478516 15.5 L 10.72712326049805 28 L 25.27287673950195 28 L 32.53094100952148 15.5 L 25.27287673950195 3 L 10.72712326049805 3 M 9 0 L 27 0 L 36 15.5 L 27 31 L 9 31 L 0 15.5 L 9 0 Z" stroke="none" fill="#fff" />
                        </g>
                        <g id="Polygon_5" data-name="Polygon 5" transform="translate(181 518) rotate(90)" fill="none">
                            <path d="M27,0l9,15.5L27,31H9L0,15.5,9,0Z" stroke="none" />
                            <path d="M 10.72712326049805 3 L 3.469058990478516 15.5 L 10.72712326049805 28 L 25.27287673950195 28 L 32.53094100952148 15.5 L 25.27287673950195 3 L 10.72712326049805 3 M 9 0 L 27 0 L 36 15.5 L 27 31 L 9 31 L 0 15.5 L 9 0 Z" stroke="none" fill="#fff" />
                        </g>
                        <g id="Polygon_6" data-name="Polygon 6" transform="translate(261 637.5) rotate(90)" fill="none">
                            <path d="M27,0l9,15.5L27,31H9L0,15.5,9,0Z" stroke="none" />
                            <path d="M 10.72712326049805 3 L 3.469058990478516 15.5 L 10.72712326049805 28 L 25.27287673950195 28 L 32.53094100952148 15.5 L 25.27287673950195 3 L 10.72712326049805 3 M 9 0 L 27 0 L 36 15.5 L 27 31 L 9 31 L 0 15.5 L 9 0 Z" stroke="none" fill="#fff" />
                        </g>
                        <path id="Path_1" data-name="Path 1" d="M35.5,62l80.119,119.524L35.5,301.932l80.119,119.5L35.5,540.011l80.119,122.232" transform="translate(130 -4)" fill="none" stroke="#fff" stroke-width="2" />
                    </g>
                </svg>
                {product.storyNodes.map(function(phrase, i) {
                    var cName = (i+1)%2 ? "odd s" + String(i+1) : "even s" + String(i+1);
                    return (<div className={cName}><p>{product.storyNodes[i]}</p></div>)
                })}
            </div>

            <div>
                <div className="auxdiv" id="video"> </div>
                <Video videoSrcURL={ product.videoUrl } videoTitle={ productRef.productName } />
            </div>
        </div>
    )
}


export default ProductHome;
