import React from "react"
import TestimonialTemplate from "../../utils/templates/TestimonialTemplate"
import Loadable from "@loadable/component"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const OwlCarousel = Loadable(() => import('react-owl-carousel'));

const Testimonials = ({ productRef, product }) => {
    return(
        <div className="testimonials">
            <div className="auxdiv" id="testimonials"></div>
            <div className="section">
                <h2>TESTIMONIALS</h2>
            </div>
            {/*<canvas class="l-quotes-canvas"></canvas>
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide c-quote">
                  <p class="c-quote__text">It looks incredible</p>
                  <span class="c-quote__author">~ VG247</span>
                </div>
                <div class="swiper-slide c-quote">
                  <p class="c-quote__text">Overwhelming with all its detail</p>
                  <span class="c-quote__author">~ GameSpot</span>
                </div>
                <div class="swiper-slide c-quote">
                  <p class="c-quote__text">A beautiful and sprawling RPG </p>
                  <span class="c-quote__author">~ IGN</span>
                </div>
                <div class="swiper-slide c-quote">
                  <p class="c-quote__text">Gameplay blew us away</p>
                  <span class="c-quote__author">~ Game Informer</span>
                </div>
              </div>
            </div>*/}
            <div className="content">
                <OwlCarousel className="owl-theme" loop items={1} nav autoplay>
                    { product.testimonials.map(function (testimonial) {
                        return <TestimonialTemplate testimonial={testimonial} />
                    }) }
                </OwlCarousel>
            </div>
        </div>
    )
}

export default Testimonials
