import React from "react"
import Img from "gatsby-image/withIEPolyfill"
// import { tns } from "tiny-slider/src/tiny-slider"
// import Loadable from "@loadable/component"

import OwlCarousel from "../owl-carousel/Owl-carousel"

const ProductFeatures = ({ productRef, product, images }) => {
    // console.log(images)
    let imageBuckets = {}
    images.map(function(i, j) {
        // console.log(i)
        if(imageBuckets[Number(i.relativeDirectory.split("/")[3])] == undefined) {
            imageBuckets[Number(i.relativeDirectory.split("/")[3])] = [];
        }
        // console.log(imageBuckets[Number(i.relativeDirectory.split("/")[3])])
        imageBuckets[Number(i.relativeDirectory.split("/")[3])].push(i);
    })

    // console.log(imageBuckets)
    return(
        <div className="productFeatures">
        <div className="auxdiv" id="features"></div>
        <div className="section">
        <h2>FEATURES</h2>
        </div>
        <div className="miniFeatures" id="miniFeatures">
    {/* <div>Image</div> */}
    <div className="miniFeaturesSpecs">
    <div>
    <h2>MISSION</h2>
    <h3>{ product.features.mini.mission}</h3>
    </div>
    <div>
    <h2>THEME</h2>
    <h3>{ product.features.mini.theme }</h3>
    </div>
    <div>
    <h2>MODES</h2>
    { product.features.mini.modes.map(function(mode) {
        return(
            <h3>{ mode.mode }</h3>
            )
    }) }
    </div>
    <div>
    <h2>AREA</h2>
    { product.features.mini.modes.map(function(mode) {
        return(
            <h3>{ mode.area.min } - { mode.area.max } { mode.area.unit }</h3>
            )
    }) }
    </div>
    <div>
    <h2>PLAYERS</h2>
    { product.features.mini.modes.map(function(mode) {
        return(
            <h3>{ mode.players.min } - { mode.players.max }</h3>
            )
    }) }
    </div>
    <div className="last">
    <h2>TIME</h2>
    { product.features.mini.modes.map(function(mode) {
        return(
            <h3>{ mode.time } min</h3>
            )
    }) }
    </div>
    </div>
    </div>
    <div className="mainFeatures" id="mainFeatures">
        { imageBuckets[NaN].map(function(i, k) {
            return(
                <Img
                    className="featureImages"
                    fluid={i.childImageSharp.fluid}
                    objectFit="contain"
                    objectPosition="50% 50%"
                />
            )
        })}
    </div>
    {/*<div className="mainFeatures" id="mainFeatures">
    { product.features.main.map(function (f, j) {
        // images.map(function(i, k) {
            //     console.log(Number(i.relativeDirectory.split("/")[3]), f)
            //     if(Number(i.relativeDirectory.split("/")[3]) == j) {
                //         console.log("yes")
                //         return <div><h4>{ f }</h4><div>{ i.relativeDirectory }</div></div>
                //     }
                // })
                // console.log(j)
                return (
                    <div className="mainFeatureContainer">
                    <h4>{f}</h4>*/}
                    {/*<img src={ previousArrow } className="changeImageButton" onClick={ () => (true) } />
                    <div className="my-slider">
                        { imageBuckets[j].map(function(i, k) {
                            // console.log(i)
                            return(
                                <div><Img className="featureImages" fluid={i.childImageSharp.fluid} /></div>
                            )
                        }) }
                    <img src={ nextArrow } className="changeImageButton" onClick={ () => (true) } />
                    </div>
                    <div className="productImage">
                        <OwlCarousel className="owl-theme" loop items={1} nav autoplay>
                        { imageBuckets[j].map(function(i, k) {
                            return(
                                <div class="item"><Img className="featureImages" fluid={i.childImageSharp.fluid} /></div>
                                )
                        }) }
                        </OwlCarousel>
                    </div>*/}{/*
                    </div>)
            })
}
</div>*/}
</div>
)
}


export default ProductFeatures
