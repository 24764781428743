import React from "react"

const TestimonialTemplate = ({ testimonial }) => {
    return(
        <div class="testimonial-item">
            <h1>{ testimonial.review }</h1>
            <h3>- { testimonial.name }</h3>
        </div>
    )
}

export default TestimonialTemplate
