import React from "react"
import { useState } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import previousArrow from "../../assets/images/left-arrow.png"
import nextArrow from "../../assets/images/right-arrow.png"


const Gallery = ({ productAssets, productRef, product }) => {
    // console.log(productAssets)
    const [index, changeIndex] = useState(0)
    const [windowStart, changeWindowStart] = useState(0)
    const windowSize = 8
    function changeCurrentImage(index) {
        // let currentImage = document.getElementsByClassName("currentImage")[0].firstChild
        // console.log(index)
        changeIndex(index);
        // currentImage.src = event.target.src
    }
    function changeWindow(flag) {
        if(flag && windowStart + windowSize < productAssets.length) changeWindowStart(windowStart + 1)
        else if(!flag && windowStart > 0) changeWindowStart(windowStart - 1)
        console.log()
    }
    return(
        <div className="gallery">
            <div className="auxdiv" id="gallery"></div>
            <div className="section">
                <h2>GALLERY</h2>
            </div>
            <div className="contentGallery">
                <div className="currentImage">
                    <Img objectFit="cover" objectPosition="50% 50%" alt='current image of gallery for laser wars, laser spy, laser buster, laser maze' fluid={ productAssets[index].childImageSharp.fluid } />
                </div>
                <div className="imageList">
                    <img src={ previousArrow } className="changeImageButton" onClick={ () => changeWindow(false) } />
                    { productAssets.map(function (photo, i) {
                        if(windowStart + windowSize > productAssets.length) {
                            if(i >= windowStart || i < (windowStart + windowSize) % productAssets.length) {
                                if(index == i)
                                    return <div onClick={ () => changeCurrentImage(i) } style={{ "borderBottom" : "2px solid #46c0e3" }}><Img objectFit="cover" objectPosition="50% 50%" fluid={ photo.childImageSharp.fluid } /></div>
                                else
                                    return <div onClick={ () => changeCurrentImage(i) }><Img objectFit="cover" objectPosition="50% 50%" fluid={ photo.childImageSharp.fluid } /></div>
                            }
                        }
                        else {
                            if(i >= windowStart && i < windowStart + windowSize){
                                if(index == i)
                                    return <div onClick={ () => changeCurrentImage(i) } style={{ "borderBottom" : "2px solid #46c0e3" }}><Img objectFit="cover" objectPosition="50% 50%" fluid={ photo.childImageSharp.fluid } /></div>
                                else
                                    return <div onClick={ () => changeCurrentImage(i) }><Img objectFit="cover" objectPosition="50% 50%" fluid={ photo.childImageSharp.fluid } /></div>
                            }
                        }
                    }) }
                    <img src={ nextArrow } className="changeImageButton" onClick={ () => changeWindow(true) } />
                </div>
            </div>
        </div>
    )
}


export default Gallery
