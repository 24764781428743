import React from "react"

import { graphql } from "gatsby"

import ProductHome from "../components/ProductsPage/ProductHome"
import ProductFeatures from "../components/ProductsPage/ProductFeatures"
import Testimonials from "../components/ProductsPage/Testimonials"
import Gallery from "../components/ProductsPage/Gallery"
import Footer from "../components/MainPage/Footer"

import "../utils/css/products.css"
import Faqs from "../components/ProductsPage/Faqs"


const ProductPage = ({ data, pageContext }) => {
    // console.log(data)
    let info = data.allInfoJson
    let assets = data.allAssets
    let assetsFeatures = data.allImages
    // console.log(assetsFeatures)
    return(
        <div className="productPage">
            <ProductHome productRef={ pageContext.product } product={ info.nodes[0].childInfoJson } />
            <ProductFeatures productRef={ pageContext.product } product={ info.nodes[0].childInfoJson } images={ assetsFeatures.nodes } />
            <Testimonials productRef={ pageContext.product } product={ info.nodes[0].childInfoJson } />
            <Gallery productRef={ pageContext.product } product={ info.nodes[0].childInfoJson } productAssets={assets.nodes} />
            <Faqs productRef={ pageContext.product } product={ info.nodes[0].childInfoJson } />
            <Footer />
        </div>
    )
}

export const query = graphql`
    query ($detailsPath:String!, $assetsPath:String!, $assetsFeaturesPath:String!) {
      allInfoJson : allFile(filter: {relativeDirectory: {regex: $detailsPath}}) {
        nodes {
          childInfoJson {
            videoUrl
            subtitle
            storyNodes
            faqs {
              a
              q
            }
            features {
              mini {
                mission
                modes {
                  area {
                    max
                    min
                    unit
                  }
                  mode
                  players {
                    max
                    min
                  }

                  time
                }
                theme
              }
              main
            }
            testimonials {
              name
              review
            }

          }
        }
      },
    allAssets : allFile(filter: {relativeDirectory: {regex: $assetsPath}}) {
        nodes {
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      },
    allImages : allFile(filter: {relativeDirectory: {regex: $assetsFeaturesPath}}) {
        nodes {
          name
          relativeDirectory
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
`

export default ProductPage
