import React from 'react';
import Loadable from "@loadable/component"
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const OwlCarousel = Loadable(() => import('react-owl-carousel'));

const carousel= () =>{
    return(
    <div>
        <OwlCarousel className="owl-theme" loop items={1} nav autoplay>
            <img></img>
            <img></img>
            <img></img>
            <img></img>
        </OwlCarousel>
    </div>
    );
}

export default carousel;
