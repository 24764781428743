import React from "react"
import { Link } from "gatsby"

import NavbarNormal from "../Navigation/NavbarNormal"
import NavbarMobile from "../Navigation/NavbarMobile"
import fogLogo from '../../../static/icons/FOG_logo.svg';


export default function Header({  }) {
    return(
        <header className="header">
            {/* <img className="logo" src={ ITLLogo } /> */}
            <Link to="/"><img className="logo" src={ fogLogo } /></Link>
            <NavbarNormal page={ "" } />
            <NavbarMobile page={ "" } />
        </header>
    )
}
